import React from "react"
import "../styles/styles.scss"

import Header from "../components-en/header"
import Demo from "../components-en/demo"
import Footer from "../components-en/footer"

import Hero from "../technology/hero-en"

const IndexPage = () => (
    <div>
      <Header />
      <Hero />
      <Demo />
      <Footer />
    </div>
)

export default IndexPage