import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Hero = () => {
    const data = useStaticQuery(graphql`
    query {
      heroimage: file(relativePath: { eq: "city-text-en.png" }) {
        childImageSharp {
          fluid(maxWidth: 1540) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `)
    return (
        <div className="hero-applications">
            <div className="container">
                <div className='column'>
                    <div>
                    <div className="main-text">
                        Digital <span>twin.</span>
                    </div>
                    <div className="sub-text">
                        A virtual copy of the waste landscape. 
                    </div>
                    </div>
                
                <div className='child'>
                <p>Using a combination of data and machine learning our technology builds a digital twin, 
                    or virtual copy, of the waste landscape in cities. This forms the basis for optimizing the process. </p>
                </div>
                <div className = 'sideimage'> 
                  <Img className = 'graphic' fluid={data.heroimage.childImageSharp.fluid} />
                </div>
                <div className='child'>
                <h3> Waste intelligence. </h3>
                <p> Our digital twin helps waste collectors and recycling companies better understand where what waste is expected. 
                    In combination with the optimization algorithms this helps find the most effective ways of upcycling the 
                    collected streams into new raw materials.  </p> 
                </div>
                <div className='child right'>
                <h3> Connecting all players. </h3>
                <p>With the digital twin we connect all players in the waste process to facilitate the
                    move to a circular way of working: what is waste for one, can be (made into) a valuable raw material for another. </p>
                </div>
                <div className='child'>
                <h3> The best valorisation. </h3>
                <p>How can the collected waste stream best be recycled? What route to drive to pick up and drop off the 
                    waste streams most effectively? How can the quality of the waste be best maintained? Our tools give you 
                    full insight into the most optimal valorisation of the various waste streams.  </p>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Hero

